import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "flex gap-[32px] border-b-[1px] border-solid pt-[24px] px-[24px]" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "p-[24px]"
}
const _hoisted_5 = { class: "grid grid-cols-2 gap-[12px] mt-[8px]" }
const _hoisted_6 = { class: "mt-[12px]" }
const _hoisted_7 = { class: "mt-[12px]" }
const _hoisted_8 = { class: "flex items-center gap-[10px] mt-[12px]" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "mt-[32px] mb-[32px]" }
const _hoisted_11 = { class: "flex gap-[32px]" }
const _hoisted_12 = {
  key: 1,
  class: "p-[24px]"
}
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "mt-[32px]" }
const _hoisted_15 = { class: "grid grid-cols-1 gap-[24px] mt-[17px]" }
const _hoisted_16 = {
  key: 2,
  class: "p-[24px]"
}
const _hoisted_17 = { class: "grid grid-cols-2 gap-[20px]" }
const _hoisted_18 = {
  key: 3,
  class: "p-[24px] grid grid-cols-1 gap-[12px]"
}
const _hoisted_19 = { class: "flex gap-[6px] justify-between items-center capitalize" }
const _hoisted_20 = {
  key: 0,
  class: ""
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }

import { getUserById, useCustomersHelper } from './Teams.helper';
import { watchEffect, ref } from 'vue'
import { Icon } from '@iconify/vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';
import { useUserFormHandler } from '@/utility/composables/useUsersFormHandler'
import CheckboxInput from '@/components/shared/checkbox-input/CheckboxInput.vue';
import ToggleSlider from '@/components/shared/toggle-slider/ToggleSlider.vue';
import { deleteFromInputList } from '@/utility/helper/utils.helper';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import UploadProfileImage from '@/components/shared/upload-profile-image/UploadProfileImage.vue';
import { useStore } from 'vuex';
import { useSettingsHandler } from '@/utility/composables/useSettingsRequestsHandler';
import { getBankDetails } from '../settings/Settings.helper';

interface Props {
    id: number | string | any
    handleOpenEditModal: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EditMember',
  props: {
    id: {},
    handleOpenEditModal: {}
  },
  setup(__props: any) {

const { scheduleData, rolesOptions, activateTab, activeTab, addMemberTabs } = useCustomersHelper()

const props = __props
const isOpen = ref(false)
const userData = ref()
const loading = ref(false)
const imageData = ref();
const store = useStore();
const banksDetailsData = ref()
const { updateWorkingHoursFormHandler } = useSettingsHandler(store)


const openWorkHourUpdate = ref<boolean[]>([]);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); 

const minDate = `${currentYear}-${currentMonth}-01`; 
const maxDate = `${currentYear}-${currentMonth}-${new Date(currentYear, currentDate.getMonth() + 1, 0).getDate()}`; 

const handleOpenWorkHourUpdateModal = (idx: number) => {
    if (openWorkHourUpdate.value.length <= idx) {
        openWorkHourUpdate.value[idx] = false;
    }
    openWorkHourUpdate.value[idx] = !openWorkHourUpdate.value[idx];
};

const deleteTimeSlot = (dayIdx: number, timeIdx: number) => {
    const updatedStoreHours = { ...workingHoursData.value.storeHours };
    updatedStoreHours[dayIdx].period.splice(timeIdx, 1); // Remove the time slot
    workingHoursData.value.storeHours = updatedStoreHours;
    updateWorkingHoursFormHandler.setValues(workingHoursData.value);
};

const handleWorkingHoursChange = (event: any, idx: number) => {
    const { name, value, checked } = event.target;
    const updatedStoreHours = { ...workingHoursData.value.storeHours };
    const updatedDay: any = { ...(updatedStoreHours[idx] || {}) };

    name === 'status' ? updatedDay[name] = checked : updatedDay[name] = value;

    updatedStoreHours[idx] = updatedDay;
    workingHoursData.value.storeHours = updatedStoreHours; 
    updateWorkingHoursFormHandler.setValues(workingHoursData.value)
};

const clearInputFields = (dayIdx: number, timeIdx: number) => {
    const updatedStoreHours = { ...workingHoursData.value.storeHours };
    updatedStoreHours[dayIdx].period[timeIdx].open = ''; 
    updatedStoreHours[dayIdx].period[timeIdx].close = '';
    workingHoursData.value.storeHours = updatedStoreHours; 
    updateWorkingHoursFormHandler.setValues(workingHoursData.value);
};

const handleCloseModal = () => {
    isOpen.value = false;
};

const { createUserFormHandler } = useUserFormHandler(props?.id, userData, store, handleCloseModal);

const workingHoursData = ref({
    storeHours: [
        { day: 'Monday', period: [{ open: '', close: '' }], status: false },
        { day: 'Tuesday', period: [{ open: '', close: '' }], status: false },
        { day: 'Wednesday', period: [{ open: '', close: '' }], status: false },
        { day: 'Thursday', period: [{ open: '', close: '' }], status: false },
        { day: 'Friday', period: [{ open: '', close: '' }], status: false },
        { day: 'Saturday', period: [{ open: '', close: '' }], status: false },
        { day: 'Sunday', period: [{ open: '', close: '' }], status: false },
    ]
});

watchEffect(() => {
    if (userData.value?.schedule) {
        const schedule = userData.value.schedule;
        workingHoursData.value.storeHours = Object.keys(schedule).map(day => ({
            day: day.charAt(0).toUpperCase() + day.slice(1), 
            period: schedule[day].period.map((slot: any) => ({
                open: slot.start, 
                close: slot.end,  
            })),
            status: schedule[day].status,
        }));
    }
});

const formatScheduleData = () => {
    const formattedSchedule: any = {};

    Object.values(workingHoursData.value.storeHours).forEach(day => {
        const dayKey = day.day.toLowerCase(); 
        formattedSchedule[dayKey] = {
            status: day.status,
            period: day.period.map(slot => ({
                start: slot.open,
                end: slot.close
            }))
        };
    });

    return formattedSchedule;
};

const addTimeSlot = (idx: number) => {
    workingHoursData.value.storeHours[idx].period.push({ open: '', close: '' });
    updateWorkingHoursFormHandler.setValues(workingHoursData.value);
};

const handleTimeSlotChange = (event: any, dayIdx: number, timeIdx: number, type: 'open' | 'close') => {
    const updatedStoreHours = { ...workingHoursData.value.storeHours };
    updatedStoreHours[dayIdx].period[timeIdx][type] = event.target.value;
    workingHoursData.value.storeHours = updatedStoreHours;
    updateWorkingHoursFormHandler.setValues(workingHoursData.value);
};


const imageDataFromEmitedBase64 = (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    imageData.value = imageDataParts?.[1];
}

watchEffect(() => {
    getUserById({userId: props.id})
    .then((res: any) => {
        userData.value = res;
        scheduleData.value = res?.schedule?.monday?.period;
    })
});

watchEffect(() => {
    createUserFormHandler.setValues(userData.value)
})

const handleCreateUserSubmit = async (event: Event) => {
    loading.value = true;

    const storeHoursArray = Array.isArray(workingHoursData.value.storeHours)
        ? workingHoursData.value.storeHours
        : Object.values(workingHoursData.value.storeHours);

    updateWorkingHoursFormHandler.errors = {};

    storeHoursArray.forEach((storeHour: any, idx) => {
        if (storeHour.status) {
            storeHour.period.forEach((timeSlot: any, timeIdx: any) => {
                if (!timeSlot.open) {
                    updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].open`] = 'Start Time is required';
                } else {
                    delete updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].open`];
                }
                if (!timeSlot.close) {
                    updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].close`] = 'End Time is required';
                } else {
                    delete updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].close`];
                }
            });
        }
    });

    if (Object.keys(updateWorkingHoursFormHandler.errors).length > 0) {
        loading.value = false;
        return;
    }

    const formattedSchedule = formatScheduleData();
    createUserFormHandler.values.imageData = imageData.value;
    createUserFormHandler.values.schedule = formattedSchedule;

    try {
        const res = await createUserFormHandler.handleSubmit(event);

        // await getUsers({ search: searchOptionsData.value.search }).then((response: any) => {
        //     usersData.value = response;
        //     teamTabs.value = [`Members (${response.total})`];
        // });
        res ? props.handleOpenEditModal() : '';

    } catch (error) {
        console.error("Form submission failed:", error);
    } finally {
        loading.value = false;
    }
};

watchEffect(() => {
    getBankDetails().then((response) => {
        banksDetailsData.value = response;
    })
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(addMemberTabs), (tab, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': _unref(activeTab) === index as any }]),
          onClick: ($event: any) => (_unref(activateTab)(index as any))
        }, _toDisplayString(tab), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("form", {
      class: "",
      onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handleCreateUserSubmit($event)), ["prevent"]))
    }, [
      (_unref(activeTab) == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(InputField, {
                label: "First Name *",
                type: "text",
                placeholder: "First Name",
                name: "firstName",
                value: userData.value?.firstName,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Last Name *",
                type: "text",
                placeholder: "Last Name",
                name: "lastName",
                value: userData.value?.lastName,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Email *",
                type: "email",
                placeholder: "Email",
                name: "email",
                value: userData.value?.email,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Phone *",
                type: "phone",
                placeholder: "080 123 456",
                name: "phone",
                value: userData.value?.phone,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Street Address",
                type: "text",
                placeholder: "Street Address",
                name: "address.street",
                value: userData.value?.address?.street,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "City ",
                type: "text",
                placeholder: "City Address",
                name: "address.city",
                value: userData.value?.address?.city,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Postal Code",
                type: "text",
                placeholder: "",
                name: "address.postalCode",
                value: userData.value?.address?.postalCode,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "State",
                type: "text",
                name: "address.state",
                placeholder: "",
                value: userData.value?.address?.state,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
            ]),
            _createVNode(InputField, {
              label: "Country",
              type: "text",
              name: "address.country",
              placeholder: "",
              class: "my-[12px]",
              value: userData.value?.address?.country,
              "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
              "onUpdate:value": _unref(createUserFormHandler).handleChange
            }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
            _createVNode(InputField, {
              label: "National ID (NIN)",
              type: "number",
              name: "nin",
              placeholder: "",
              value: userData.value?.nin,
              "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
              "onUpdate:value": _unref(createUserFormHandler).handleChange
            }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(DropdownField, {
                label: "Status *",
                name: "status",
                value: userData.value?.status,
                onChange: _unref(createUserFormHandler).handleChange,
                optionsData: [{name: 'Active', id: 'active'}, {name: 'Disabled', id: 'disabled'}]
              }, null, 8, ["value", "onChange"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(DropdownField, {
                label: "Role *",
                name: "role",
                defaultOptionLabel: "Select",
                value: userData.value?.role,
                onChange: _unref(createUserFormHandler).handleChange,
                optionsData: _unref(rolesOptions)
              }, null, 8, ["value", "onChange", "optionsData"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userData.value?.roles, (role, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption",
                  key: idx
                }, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(role), 1),
                  _createVNode(_unref(Icon), {
                    icon: "ant-design:close-outlined",
                    width: "12",
                    height: "12",
                    onClick: () => _unref(deleteFromInputList)('roles', idx, userData.value, _unref(createUserFormHandler))
                  }, null, 8, ["onClick"])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[1] || (_cache[1] = _createElementVNode("label", { class: "text-body-2 text-grey-200 mb-[7px] inline-block" }, "Gender", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(RadioButton, {
                  value: "Male",
                  isChecked: userData.value?.gender === 'Male',
                  name: "gender",
                  "onOn:change": _unref(createUserFormHandler).handleChange
                }, null, 8, ["isChecked", "onOn:change"]),
                _createVNode(RadioButton, {
                  value: "Female",
                  isChecked: userData.value?.gender === 'Female',
                  name: "gender",
                  "onOn:change": _unref(createUserFormHandler).handleChange
                }, null, 8, ["isChecked", "onOn:change"])
              ])
            ]),
            _createVNode(UploadProfileImage, {
              imageUrl: userData.value?.avatar,
              name: "imageData",
              onBase64ImgCode: imageDataFromEmitedBase64,
              "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
              "onUpdate:value": _unref(createUserFormHandler).handleFileChange
            }, null, 8, ["imageUrl", "onUpdate:blur:value", "onUpdate:value"])
          ]))
        : _createCommentVNode("", true),
      (_unref(activeTab) == null)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(DropdownField, {
                label: "Role *",
                optionsData: ['Cashier', 'Manager', 'Sale Rep']
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[2] || (_cache[2] = _createElementVNode("label", { class: "text-body-2 text-grey-200 mb-[7px] inline-block" }, "Permissions", -1)),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(CheckboxInput, { label: "Manage orders: Create, modify, and delete orders as needed." }),
                _createVNode(CheckboxInput, { label: "View order history: Access a record of past orders for reference." }),
                _createVNode(CheckboxInput, { label: "Mark orders as paid: Update the status of orders to indicate payment completion." }),
                _createVNode(CheckboxInput, { label: "Manage discounts: Apply discounts to orders when applicable." }),
                _createVNode(CheckboxInput, { label: "Handle refunds: Process refunds for returned or canceled orders." }),
                _createVNode(CheckboxInput, { label: "Update customer information: Make changes to customer details as necessary." })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(activeTab) == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(InputField, {
                label: "Salary",
                prefixIcon: "fa6-solid:naira-sign",
                type: "number",
                name: "finance.salary",
                value: userData.value?.finance?.salary,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Tax *",
                prefixIcon: "fa6-solid:naira-sign",
                type: "number",
                name: "finance.tax",
                value: userData.value?.finance?.tax,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Bank Account Name *",
                type: "text",
                placeholder: "Account name",
                name: "finance.accountName",
                value: userData.value?.finance?.accountName,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Bank Account Number *",
                type: "phone",
                placeholder: "080 123 456",
                name: "finance.accountNumber",
                value: userData.value?.finance?.accountNumber,
                "onUpdate:blur:value": _unref(createUserFormHandler).handleBlur,
                "onUpdate:value": _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(DropdownField, {
                label: "Select Bank",
                name: "finance.bankName",
                value: userData.value?.finance?.bankName,
                optionsData: banksDetailsData.value,
                onChange: _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "optionsData", "onChange"]),
              _createVNode(DropdownField, {
                label: "Payment cycle",
                value: userData.value?.finance?.paymentCycle,
                name: "finance.paymentCycle",
                optionsData: [
                            {name: 'Weekly', value: 'weekly'},
                            {name: 'Bi-Weekly', value: 'bi-weekly'},
                            {name: 'Monthly', value: 'monthly'}
                        ],
                onChange: _unref(createUserFormHandler).handleChange
              }, null, 8, ["value", "onChange"]),
              _createVNode(InputField, {
                type: "date",
                label: "Payment day",
                name: "finance.paymentDate",
                placeholder: "Start date",
                value: userData.value?.finance?.paymentDate,
                onChange: _unref(createUserFormHandler).handleChange,
                min: minDate,
                max: maxDate
              }, null, 8, ["value", "onChange"])
            ]),
            _createVNode(TextArea, {
              label: "Note",
              placeholder: "Enter note",
              class: "mt-[24px] w-full",
              name: "finance.paymentNote",
              value: userData.value?.finance?.paymentNote,
              "onUpdate:value": _unref(createUserFormHandler).handleChange
            }, null, 8, ["value", "onUpdate:value"])
          ]))
        : _createCommentVNode("", true),
      (_unref(activeTab) == 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(workingHoursData.value?.storeHours, (storeHour, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "border-[1px] border-solid border-grey-50 p-[14px] rounded-[8px] mt-[12px]",
                key: idx
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex items-center justify-between w-[100%]", { 'opacity-50': !storeHour.status }])
                }, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(ToggleSlider, {
                      name: "status",
                      isChecked: storeHour.status,
                      "onOn:change": (event) => handleWorkingHoursChange(event, idx),
                      label: storeHour?.day
                    }, null, 8, ["isChecked", "onOn:change", "label"])
                  ]),
                  _createVNode(_unref(Icon), {
                    icon: !openWorkHourUpdate.value[idx] ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                    class: _normalizeClass(["text-primary-300 cursor-pointer", { 'text-primary-300 cursor-not-allowed': !storeHour.status }]),
                    onClick: ($event: any) => (storeHour.status && handleOpenWorkHourUpdateModal(idx))
                  }, null, 8, ["icon", "class", "onClick"])
                ], 2),
                (openWorkHourUpdate.value[idx] && storeHour.status)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(storeHour.period, (timeSlot, timeIdx) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: timeIdx,
                          class: "work-hours-card items-center gap-[20px] mt-[16px]"
                        }, [
                          _createVNode(InputField, {
                            label: "Start Time",
                            type: "time",
                            name: "open",
                            prefixIcon: "ant-design:clock-circle-outlined",
                            value: timeSlot.open,
                            "onUpdate:blur:value": _unref(updateWorkingHoursFormHandler).handleBlur,
                            "onUpdate:value": (event) => handleTimeSlotChange(event, idx, timeIdx, 'open'),
                            errors: storeHour.status ? _unref(updateWorkingHoursFormHandler).errors[`storeHours[${idx}].period[${timeIdx}].open`] : ''
                          }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value", "errors"]),
                          _createVNode(InputField, {
                            label: "End Time",
                            type: "time",
                            name: "close",
                            prefixIcon: "ant-design:clock-circle-outlined",
                            value: timeSlot.close,
                            "onUpdate:blur:value": _unref(updateWorkingHoursFormHandler).handleBlur,
                            "onUpdate:value": (event) => handleTimeSlotChange(event, idx, timeIdx, 'close'),
                            errors: storeHour.status ? _unref(updateWorkingHoursFormHandler).errors[`storeHours[${idx}].period[${timeIdx}].close`] : ''
                          }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value", "errors"]),
                          _createElementVNode("div", {
                            class: "text-grey-300 bg-bg-200 py-[10px] px-[10px] rounded-[4px] mt-[24px] w-[fit-content]",
                            onClick: ($event: any) => (clearInputFields(idx, timeIdx))
                          }, [
                            _createVNode(_unref(Icon), {
                              icon: "ant-design:clear-outlined",
                              width: "24",
                              height: "24"
                            })
                          ], 8, _hoisted_21),
                          _createElementVNode("div", {
                            class: "text-primary-300 bg-bg-200 py-[10px] px-[10px] rounded-[4px] mt-[24px] w-[fit-content]",
                            onClick: ($event: any) => (deleteTimeSlot(idx, timeIdx))
                          }, [
                            _createVNode(_unref(Icon), {
                              icon: "ant-design:delete-outlined",
                              width: "24",
                              height: "24"
                            })
                          ], 8, _hoisted_22)
                        ]))
                      }), 128)),
                      _createElementVNode("div", {
                        class: "text-primary-300 bg-bg-200 py-[10px] px-[10px] rounded-[4px] mt-[24px] w-[fit-content] mx-auto",
                        onClick: ($event: any) => (addTimeSlot(idx))
                      }, [
                        _createVNode(_unref(Icon), {
                          icon: "ant-design:plus-outlined",
                          width: "24",
                          height: "24"
                        })
                      ], 8, _hoisted_23)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("footer", _hoisted_24, [
        _createVNode(ActionButton, {
          label: "Cancel",
          theme: "secondary",
          class: "w-[fit-content]",
          onClick: handleCloseModal
        }),
        _createVNode(ActionButton, {
          theme: "primary",
          class: "w-[fit-content]",
          isSubmit: true,
          disable: loading.value,
          label: loading.value ? 'Loading...' : 'Save'
        }, null, 8, ["disable", "label"])
      ])
    ], 32)
  ]))
}
}

})