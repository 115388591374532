import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-[72px] w-[800px] m-[auto]" }
const _hoisted_2 = { class: "flex items-center gap-[6px] text-body-1" }
const _hoisted_3 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] mt-[32px]" }
const _hoisted_4 = { class: "flex justify-between gap-[26px] py-[24px] px-[32px]" }
const _hoisted_5 = { class: "flex items-center gap-[26px]" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "font-semibold text-heading-5 mt-[6px]" }
const _hoisted_10 = { class: "text-body-2 text-grey-100" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "flex items-center justify-between py-[18px] px-[24px] bg-bg-200 border-b-[1px] border-solid border-grey-50 mb-[24px]" }
const _hoisted_15 = { class: "flex items-center gap-[10px] text-grey-200 text-body-2" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = { class: "grid grid-cols-2 gap-[24px] px-[24px] py-[28px]" }
const _hoisted_19 = { class: "" }
const _hoisted_20 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_21 = { class: "" }
const _hoisted_22 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_23 = { class: "" }
const _hoisted_24 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_25 = { class: "" }
const _hoisted_26 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_27 = { class: "" }
const _hoisted_28 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_29 = { class: "" }
const _hoisted_30 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_31 = { class: "" }
const _hoisted_32 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_33 = { class: "flex items-center justify-between py-[18px] px-[24px] bg-bg-200 border-b-[1px] border-solid border-grey-50 mb-[24px]" }
const _hoisted_34 = { class: "flex items-center gap-[10px] text-grey-200 text-body-2" }
const _hoisted_35 = { class: "" }
const _hoisted_36 = { class: "grid grid-cols-2 gap-[24px] px-[24px] py-[28px]" }
const _hoisted_37 = { class: "" }
const _hoisted_38 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_39 = { class: "" }
const _hoisted_40 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_41 = { class: "" }
const _hoisted_42 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_43 = { class: "" }
const _hoisted_44 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_45 = { class: "" }
const _hoisted_46 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_47 = { class: "flex items-center justify-between py-[18px] px-[24px] bg-bg-200 border-b-[1px] border-solid border-grey-50" }
const _hoisted_48 = { class: "flex items-center gap-[10px] text-grey-200 text-body-2" }
const _hoisted_49 = { class: "" }
const _hoisted_50 = { class: "border-t-[1px] border-solid border-grey-50 px-[32px]" }
const _hoisted_51 = { class: "flex gap-[32px] border-b-[1px] border-solid mt-[24px]" }
const _hoisted_52 = ["onClick"]
const _hoisted_53 = { class: "my-[28px]" }
const _hoisted_54 = { class: "w-[800px] m-[auto] py-[72px]" }
const _hoisted_55 = { class: "" }
const _hoisted_56 = { class: "text-heading-3 flex items-center gap-[24px] mb-[28px]" }

import { useRouter } from 'vue-router';
import { getUserById, useCustomersHelper } from './Teams.helper';
import { watchEffect, ref, onMounted } from 'vue'
import { Icon } from '@iconify/vue';
import EditMember from './EditMember.vue'
import TableField from '@/components/shared/table/TableField.vue';
import StatusLabel from '@/components/shared/status-label/StatusLabel.vue';
import { useUserFormHandler } from '@/utility/composables/useUsersFormHandler'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

interface Props {
    id: number | string | any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TeamDetails',
  props: {
    id: {}
  },
  setup(__props: any) {

const { AppWrapper, scheduleHeader, scheduleData } = useCustomersHelper()

const router = useRouter()

const props = __props

const showOrderInfo = ref(true)
const showFinanceInfo = ref(true)
const showScheduleInfo = ref(true)
const isOpen = ref(false)
const userData = ref()
const loading = ref<boolean>(true)
const store = useStore()
const route = useRoute();
const activeScheduleTab = ref({
    label: 'Mon',
    value: 'monday'
});
const scheduleDayTabs = ref([{
    label: 'Mon',
    value: 'monday'
},
{
    label: 'Tue',
    value: 'tuesday'
},{
    label: 'Wed',
    value: 'wednesday'
},
{
    label: 'Thurs',
    value: 'thursday'
},
{
    label: 'Fri',
    value: 'friday'
},
{
    label: 'Sat',
    value: 'saturday'
},
{
    label: 'Sun',
    value: 'sunday'
}]);


const activateDayTab = (tab: any) => {
    activeScheduleTab.value = tab;
    scheduleData.value = userData?.value.schedule[tab.value].status ? userData?.value.schedule[tab.value].period : [];
}

const handleShowScheduleInformation = () => {
    showScheduleInfo.value = !showScheduleInfo.value
}

const handleShowOrderInformation = () => {
    showOrderInfo.value = !showOrderInfo.value
}

const handleShowFinanceInfo = () => {
    showFinanceInfo.value = !showFinanceInfo.value
}

const handleOpenEditModal = () => {
    isOpen.value = !isOpen.value;
};

const handleCloseModal = () => {
    isOpen.value = false;
};

const { createUserFormHandler } = useUserFormHandler(props?.id, userData, store, handleCloseModal)

watchEffect(() => {
    getUserById({userId: props.id})
    .then((res: any) => {
        userData.value = res;
        scheduleData.value = res?.schedule?.monday?.period;
        loading.value = false
    })
});

watchEffect(() => {
    createUserFormHandler.setValues(userData.value)
})



function getEditModalValue(search: string) {
    const params = new URLSearchParams(search);
    return params.get('editModal');
}

onMounted(() => {
    const editModal = getEditModalValue(route.query.search as string);
    
    if (editModal === 'true') {
        isOpen.value = true;
    }
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AppWrapper), { activeTab: "Teams" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "flex items-center gap-[6px] text-grey-100 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).back()))
          }, [
            _createVNode(_unref(Icon), { icon: "ant-design:shopping-outlined" }),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, "Teams", -1))
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", null, "/", -1)),
          _createElementVNode("div", null, _toDisplayString(`${userData.value?.firstName} ${userData.value?.lastName}`), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("header", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: "order-image rounded-[8px]",
                  style: _normalizeStyle({ backgroundImage: `url(${userData.value?.avatar}?v=${new Date().getTime()})`})
                }, null, 4)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(StatusLabel, {
                    status: userData.value?.status
                  }, null, 8, ["status"])
                ]),
                _createElementVNode("div", _hoisted_9, _toDisplayString(`${userData.value?.firstName} ${userData.value?.lastName}`), 1),
                _createElementVNode("div", _hoisted_10, "Added on " + _toDisplayString(userData.value?.createdDate), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", {
                class: "order-image rounded-[8px]",
                style: _normalizeStyle({ backgroundImage: `url(${userData.value?.qrCode}?v=${new Date().getTime()})`})
              }, null, 4)
            ]),
            _createElementVNode("div", {
              class: "flex items-center gap-[10px] border-solid border-[1px] h-[fit-content] px-[10px] py-[5px] rounded-[8px] cursor-pointer",
              onClick: handleOpenEditModal
            }, [
              _createVNode(_unref(Icon), { icon: "ant-design:edit-outlined" }),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "" }, "Edit", -1))
            ])
          ]),
          _createElementVNode("main", _hoisted_12, [
            _createElementVNode("section", _hoisted_13, [
              _createElementVNode("header", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_unref(Icon), {
                      icon: "ant-design:user-outlined",
                      class: "w-[24px] h-[24px]"
                    })
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "" }, "Basic Information", -1))
                ]),
                _createElementVNode("div", {
                  class: "text-grey-200 cursor-pointer",
                  onClick: handleShowOrderInformation
                }, [
                  _createVNode(_unref(Icon), {
                    icon: showOrderInfo.value ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                    class: "w-[18px] h-[18px]"
                  }, null, 8, ["icon"])
                ])
              ]),
              _createElementVNode("main", _hoisted_17, [
                _withDirectives(_createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "First Name", -1)),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(userData.value?.firstName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Last Name", -1)),
                    _createElementVNode("div", _hoisted_22, _toDisplayString(userData.value?.lastName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Email", -1)),
                    _createElementVNode("div", _hoisted_24, _toDisplayString(userData.value?.email), 1)
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Phone", -1)),
                    _createElementVNode("div", _hoisted_26, _toDisplayString(userData.value?.phone), 1)
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Role", -1)),
                    _createElementVNode("div", _hoisted_28, _toDisplayString(userData.value?.role), 1)
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Home Address", -1)),
                    _createElementVNode("div", _hoisted_30, _toDisplayString(userData.value?.address == null ? '' : `${userData.value?.address?.street || ''}, ${userData.value?.address?.lga || ''} ${userData.value?.address?.city || ''} ${userData.value?.address?.country || ''} `), 1)
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "National ID (NIN)", -1)),
                    _createElementVNode("div", _hoisted_32, _toDisplayString(userData.value?.nin || 'N/A'), 1)
                  ])
                ], 512), [
                  [_vShow, showOrderInfo.value]
                ]),
                _createElementVNode("header", _hoisted_33, [
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("div", _hoisted_35, [
                      _createVNode(_unref(Icon), {
                        icon: "ant-design:idcard-outlined",
                        class: "w-[24px] h-[24px]"
                      })
                    ]),
                    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "" }, "Finance Information", -1))
                  ]),
                  _createElementVNode("div", {
                    class: "text-grey-200 cursor-pointer",
                    onClick: handleShowFinanceInfo
                  }, [
                    _createVNode(_unref(Icon), {
                      icon: showFinanceInfo.value ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                      class: "w-[18px] h-[18px]"
                    }, null, 8, ["icon"])
                  ])
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, [
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Salary", -1)),
                    _createElementVNode("div", _hoisted_38, _toDisplayString(userData.value?.finance?.salary), 1)
                  ]),
                  _createElementVNode("div", _hoisted_39, [
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Tax", -1)),
                    _createElementVNode("div", _hoisted_40, _toDisplayString(userData.value?.finance?.tax), 1)
                  ]),
                  _createElementVNode("div", _hoisted_41, [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Account Name", -1)),
                    _createElementVNode("div", _hoisted_42, _toDisplayString(userData.value?.finance?.accountName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_43, [
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Account Number", -1)),
                    _createElementVNode("div", _hoisted_44, _toDisplayString(userData.value?.finance?.accountNumber), 1)
                  ]),
                  _createElementVNode("div", _hoisted_45, [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Bank Name", -1)),
                    _createElementVNode("div", _hoisted_46, _toDisplayString(userData.value?.finance?.bankName), 1)
                  ])
                ], 512), [
                  [_vShow, showFinanceInfo.value]
                ]),
                _createElementVNode("header", _hoisted_47, [
                  _createElementVNode("div", _hoisted_48, [
                    _createElementVNode("div", _hoisted_49, [
                      _createVNode(_unref(Icon), {
                        icon: "ant-design:clock-circle-outlined",
                        class: "w-[24px] h-[24px]"
                      })
                    ]),
                    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "" }, "Schedules", -1))
                  ]),
                  _createElementVNode("div", {
                    class: "text-grey-200 cursor-pointer",
                    onClick: handleShowScheduleInformation
                  }, [
                    _createVNode(_unref(Icon), {
                      icon: showScheduleInfo.value ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                      class: "w-[18px] h-[18px]"
                    }, null, 8, ["icon"])
                  ])
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_50, [
                  _createElementVNode("div", _hoisted_51, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scheduleDayTabs.value, (tab) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: tab.value,
                        class: _normalizeClass(['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeScheduleTab.value.value === tab.value as any }]),
                        onClick: ($event: any) => (activateDayTab(tab as any))
                      }, _toDisplayString(tab.label), 11, _hoisted_52))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_53, [
                    _createVNode(TableField, {
                      headers: _unref(scheduleHeader),
                      dataItems: _unref(scheduleData),
                      loading: loading.value
                    }, null, 8, ["headers", "dataItems", "loading"])
                  ])
                ], 512), [
                  [_vShow, showScheduleInfo.value]
                ])
              ])
            ])
          ])
        ])
      ], 512), [
        [_vShow, !isOpen.value]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_54, [
        _createElementVNode("div", _hoisted_55, [
          _createElementVNode("div", _hoisted_56, [
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: handleOpenEditModal
            }, [
              _createVNode(_unref(Icon), { icon: "ant-design:arrow-left-outlined" })
            ]),
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "font-semibold" }, "Edit Member", -1))
          ])
        ]),
        _createVNode(EditMember, {
          id: _ctx.id,
          handleOpenEditModal: handleOpenEditModal
        }, null, 8, ["id"])
      ], 512), [
        [_vShow, isOpen.value]
      ])
    ]),
    _: 1
  }))
}
}

})